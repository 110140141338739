import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <div className="h-screen flex items-center justify-center text-white flex-wrap" style={{background:`#da2467`}}>
        <Seo title="Not found" />
        <p className="mb-0"><strong>Page Not Found...</strong> <br />The page doesn't exist. So go back <Link to="/"><u>Home</u></Link></p>
    </div>
)   

export default NotFoundPage